import { create } from 'zustand';

export interface MainStore {
   loading: boolean;

   setLoading: (loading: boolean) => void;
}

export const useMainStore = create<MainStore>()(
   (set, get): MainStore => ({
      loading: false,

      setLoading: (loading: boolean) => set({ loading }),
   }),
);

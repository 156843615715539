import { isEmpty } from '@bridgemoney/core';
import { getEnv } from './env';

export function extractListFromQuery<T>(query: URLSearchParams, name: string): T[] {
   if (query.has(name) && query.get(name) !== null) {
      // @ts-ignore
      return query.get(name).split(',');
   }

   return [];
}

export function extractStringParamFromUrl(param: string, query: URLSearchParams): string {
   return query.get(param) || '';
}

export function extractNumberParamFromUrl(param: string, query: URLSearchParams, defaultValue?: number): number {
   // @ts-ignore
   return query.has(param) ? parseInt(query.get(param)) : defaultValue || 0;
}

export function listToQuery(url: string, list: string[], name: string): string {
   if (list.length > 0) {
      const str = list.join(',');

      if (str) {
         url += `&${name}=${encodeURIComponent(str)}`;
      }

      return url;
   }

   return url;
}

export function valueToQuery(url: string, value: any, name: string): string {
   if (value) {
      url += `&${name}=${encodeURIComponent(value)}`;
   }

   return url;
}

export function interpolateUrl(url: string, args: any): string {
   let finalUrl = url;

   Object.keys(args).forEach((key: string) => {
      finalUrl = finalUrl.replace(`:${key}`, args[key]);
   });

   return finalUrl;
}

export function getCIOCustomerLink(customer_id: string): string {
   const env = getEnv();
   const workspace = env === 'production' ? '106475' : '106829';

   return `https://fly.customer.io/workspaces/${workspace}/journeys/people/${customer_id}`;
}

export function getTabRoute(root: string, tabName: string) {
   let subUrl = '';
   if (!isEmpty(tabName)) {
      subUrl = `/${tabName}`;
   }

   return `${root}${subUrl}`;
}

import React from 'react';
import { Route } from 'routes/Routes';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import styles from './NavigationLink.module.scss';
import clsx from 'clsx';

export interface NavigationLinkProps {
   to?: string;
   route: Route;
   activeRoute: Route | null;
   showIcon?: boolean;
   titleVisible?: boolean;
}

const NavigationLink = React.memo(
   ({ route, activeRoute, to, showIcon = true, titleVisible = true }: NavigationLinkProps): JSX.Element => {
      return (
         <Link to={to ? to : route.route} className={styles.link} title={route.title}>
            <ListItemButton
               className={styles.list_item}
               component="li"
               selected={!!activeRoute && activeRoute.route === route.route}
            >
               <ListItemIcon className={styles.icon}>
                  {showIcon ? <img src={route.icon} className={styles.img} alt="" /> : null}
               </ListItemIcon>
               <ListItemText
                  primary={route.title}
                  primaryTypographyProps={{
                     className: clsx(styles.list_item_text, titleVisible ? styles.visible : ''),
                  }}
               />
            </ListItemButton>
         </Link>
      );
   },
);

export { NavigationLink };
export default NavigationLink;

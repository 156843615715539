import { create, GetState, SetState } from 'zustand';
import {
   switchNavigationDrawer,
   switchTheme,
   switchToolsDrawer,
   switchEnhancedDisputeSearch,
   switchEnhancedFraudEventSearch,
   switchEnhancedReportSearch,
} from './actions';
import { persist } from 'zustand/middleware';

interface SettingsStoreData {
   theme: 'light' | 'dark';
   navigationDrawerOpened: boolean;
   toolsDrawerOpened: boolean;
   enhancedDisputeSearch: boolean;
   enhancedFraudEventSearch: boolean;
   enhancedReportSearch: boolean;
   showInstructions: boolean;
}

interface SettingsStoreActions {
   clear: () => void;

   switchTheme: () => void;
   switchNavigationDrawer: () => void;
   switchToolsDrawer: () => void;
   switchShowInstructions: () => void;

   switchEnhancedDisputeSearch: () => void;
   switchEnhancedFraudEventSearch: () => void;
   switchEnhancedReportSearch: () => void;
}

export type SettingsStore = SettingsStoreData & SettingsStoreActions;

const initialState: SettingsStoreData = {
   theme: 'dark',
   navigationDrawerOpened: true,
   toolsDrawerOpened: false,
   enhancedDisputeSearch: false,
   enhancedFraudEventSearch: true,
   enhancedReportSearch: false,
   showInstructions: true,
};

const persistentStore: any = persist(
   (set: SetState<SettingsStore>, get: GetState<SettingsStore>): SettingsStore => {
      return {
         ...initialState,

         clear: () => set({ ...initialState }),

         switchTheme: switchTheme.bind(null, set, get),
         switchNavigationDrawer: switchNavigationDrawer.bind(null, set, get),
         switchToolsDrawer: switchToolsDrawer.bind(null, set, get),
         switchEnhancedDisputeSearch: switchEnhancedDisputeSearch.bind(null, set, get),
         switchEnhancedFraudEventSearch: switchEnhancedFraudEventSearch.bind(null, set, get),
         switchEnhancedReportSearch: switchEnhancedReportSearch.bind(null, set, get),
         switchShowInstructions: () => {
            set({ showInstructions: !get().showInstructions });
         },
      };
   },
   {
      name: 'settings',
   },
);

export const useSettingsStore = create<SettingsStore>(persistentStore);

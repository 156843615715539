import React from 'react';

export interface UseInterval {
   interval: (action?: () => void, timeout?: number, interval?: boolean) => void;
   clearInterval: () => void;
}

export function useInterval(): UseInterval {
   const timerRef = React.useRef<any>(0);

   function interval(action?: () => void, timeout: number = 500) {
      timerRef.current = setInterval(() => {
         if (action) {
            action();
         }
      }, timeout);
   }

   function stop() {
      clearInterval(timerRef.current);
   }

   return {
      interval,
      clearInterval: stop,
   };
}

export type Env = 'development' | 'staging' | 'production' | 'local';

export const isProduction = (): boolean => getEnv() === 'production';

export const shouldCacheInstructions = (): boolean => import.meta.env.VITE_CACHE_INSTRUCTIONS === 'true';

export const getApiUrl = (): string => import.meta.env.VITE_API_URL || '';

export const getUnitApiUrl = (): string => import.meta.env.VITE_UNIT_URL || '';

export const getSentryDsn = (): string => import.meta.env.VITE_SENTRY_DSN || '';

export const isMock = (): boolean => import.meta.env.VITE_MOCK === 'true';

export const getEnv = (): Env => {
   return (import.meta.env.VITE_APP_ENV || 'development') as Env;
};

export const getMode = (): Env => {
   return (import.meta.env.MODE || 'development') as Env;
};

import React from 'react';
import { List } from '@mui/material';
import { Customer } from '../../../types';
import CustomerListItem from './CustomerListItem';
import { Paper } from '../../mui';
import { NoData } from '../../common';

import styles from './SearchResult.module.scss';

export interface SearchResultProps {
   data: Customer[];
   onClick: (id: string) => void;
}

const SearchResult = React.memo(({ data, onClick }: SearchResultProps): JSX.Element => {
   return (
      <Paper elevation={3} className={styles.root}>
         {data.length === 0 ? (
            <NoData title="No results found." className={styles.no_data} />
         ) : (
            <List>
               {data.map((c: Customer) => {
                  return <CustomerListItem key={c.id} customer={c} onClick={onClick} />;
               })}
            </List>
         )}
      </Paper>
   );
});

export default SearchResult;

import { useTask } from '../useTask';
import { useInstance } from '@bridgemoney/core';
import { BatchWorkflowsService } from '../../../services/BatchWorkflowsService';
import { BatchWorkflowsStore, useBatchWorkflowsStore } from '../../BatchWorkflowsStore';
import { BatchWorkflow, BatchWorkflowStatus } from '../../../types';
import { useTimeout } from '../../../hooks';

export interface UseCheckBatchWorkflowStatus {
   checkBatchWorkflowStatus: (id: string) => Promise<boolean>;
}

export function useCheckBatchWorkflowStatus(): UseCheckBatchWorkflowStatus {
   const task = useTask();

   const service = useInstance<BatchWorkflowsService>('BatchWorkflowsService');
   const queue = useBatchWorkflowsStore((state: BatchWorkflowsStore) => state.queue);
   const setQueue = useBatchWorkflowsStore((state: BatchWorkflowsStore) => state.setQueue);
   const workflows = useBatchWorkflowsStore((state: BatchWorkflowsStore) => state.workflows);
   const setWorkflows = useBatchWorkflowsStore((state: BatchWorkflowsStore) => state.setWorkflows);
   const removeWorkflowFromQueue = useBatchWorkflowsStore(
      (state: BatchWorkflowsStore) => state.removeWorkflowFromQueue,
   );
   const timeout = useTimeout();

   async function checkBatchWorkflowStatus(id: string): Promise<boolean> {
      return await task.run(async () => {
         const res = await service.fetchWorkflowById(id);

         if (!res.error && res.data) {
            const wf: BatchWorkflow = res.data as BatchWorkflow;

            processQueue(wf);
            processWorkflows(wf);
         }
      });
   }

   function processQueue(wf: BatchWorkflow) {
      const newQueue = queue.map((w: BatchWorkflow) => {
         if (w.id === wf.id) {
            return wf;
         }

         return w;
      });

      setQueue(newQueue);

      if (wf.status === BatchWorkflowStatus.COMPLETE) {
         timeout.timeout(() => {
            removeWorkflowFromQueue(wf);
         }, 2000);
      }
   }

   function processWorkflows(wf: BatchWorkflow) {
      if (workflows) {
         const newWorkflows = Object.assign({}, workflows);
         newWorkflows.records = workflows.records.map((w: BatchWorkflow) => {
            if (w.id === wf.id) {
               return wf;
            }

            return w;
         });

         setWorkflows(newWorkflows);
      }
   }

   return {
      checkBatchWorkflowStatus,
   };
}

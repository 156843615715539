import { Agent } from 'types';

export enum Permissions {
   EXPORT_REPORTS = 'export_reports',
   MANAGE_PRODUCT = 'manage_product',
   MANAGE_AGENTS = 'manage_agents',
   MANAGE_RISK_OPS = 'manage_risk_ops',
   MANAGE_RISK_TAGS = 'manage_risk_tags',
   MANAGE_MARKETING = 'manage_marketing',
   MANAGE_MEMBERS = 'manage_members',
   MANAGE_MS_TAGS = 'manage_ms_tags',
   MANAGE_LABS = 'manage_labs',
   MANAGE_ARRAY = 'manage_array',
   VIEW_MEMBERS = 'view_members',
   VIEW_AGENTS = 'view_agents',
   VIEW_REPORTS = 'view_reports',
   VIEW_LABS = 'view_labs',
   VIEW_ARRAY = 'view_array',
}

export class PermissionsService {
   can(agent: Agent, permissions: Permissions[]): boolean {
      return agent.permissions.some((ap: Permissions) => permissions.includes(ap));
   }
}

export enum Namespaces {
   ADMIN = 'admin',
   BRIDGE_CAFE = 'cafe',
   DATA_DEPOT = 'data_depot',
   LABS = 'labs',
   LATTICE = 'lattice',
   MARKETING = 'marketing',
   REFERRALS = 'referrals',
   SAILFISH = 'sailfish',
   SHIPYARD = 'shipyard',
}

export const isNamespace = (namespace?: any): boolean => {
   switch (namespace) {
      case Namespaces.ADMIN:
      case Namespaces.BRIDGE_CAFE:
      case Namespaces.DATA_DEPOT:
      case Namespaces.LABS:
      case Namespaces.LATTICE:
      case Namespaces.MARKETING:
      case Namespaces.REFERRALS:
      case Namespaces.SAILFISH:
      case Namespaces.SHIPYARD:
         return true;
      default:
         return false;
   }
};

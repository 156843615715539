import React from 'react';
import { useExecutor } from '../hooks';
import { Constants, Events } from '../constants';
import { Storage } from '../services';
import { Agent } from '../types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Constants as CoreConstants, EventEmitter } from '@bridgemoney/core';
import { Routes } from './Routes';
import { UserStore, useUserStore } from '../store';
import * as H from 'history';
import { PublicRoutes } from './PublicRoutes';
import { AuthRoutes } from './AuthRoutes';
import { OrbitalProgress } from '../components/mui';
import { useFetchAgent } from '../store/actions';

function checkAuth(): boolean {
   const token = Storage.getItem<string>(CoreConstants.TOKEN_KEY);
   const tokenExpiresAt = Storage.getItem<number>(CoreConstants.TOKEN_EXP_TIMESTAMP_KEY);
   const passwordResetRequired = Storage.getItem<boolean | null>(Constants.AGENT_PASSWORD_RESET_REQUIRED);

   if (token && tokenExpiresAt && !passwordResetRequired) {
      return tokenExpiresAt > new Date().getTime() / 1000;
   } else {
      return false;
   }
}

function clearAuth() {
   Storage.removeItem(CoreConstants.TOKEN_KEY);
   Storage.removeItem(CoreConstants.TOKEN_EXP_TIMESTAMP_KEY);
   Storage.removeItem(Constants.AGENT_PASSWORD_RESET_REQUIRED);
}

enum AppState {
   INITIAL,
   AUTHENTICATED,
   UNAUTHENTICATED,
}

function getPath(location: H.Location): string {
   return location.pathname + location.search;
}

function AppRoutes(): JSX.Element {
   const [appState, setAppState] = React.useState<AppState>(AppState.INITIAL);
   const [referrer, setReferrer] = React.useState<string | null>(null);
   const [storeReferrer, setStoreReferrer] = React.useState<boolean>(true);

   const location = useLocation();
   const navigate = useNavigate();

   const agent: Agent | null = useUserStore((state: UserStore) => state.agent);
   const { fetchAgent } = useFetchAgent();

   useExecutor(
      () => setAppState(checkAuth() ? AppState.AUTHENTICATED : AppState.UNAUTHENTICATED),
      Constants.TOKEN_CHECK_INTERVAL,
      true,
   );

   React.useEffect(() => {
      async function updateAuth(data?: any) {
         if (data && data.signOutByUser) {
            setStoreReferrer(false);
            setReferrer(null);
         }

         let state = checkAuth() ? AppState.AUTHENTICATED : AppState.UNAUTHENTICATED;

         if (state === AppState.AUTHENTICATED && agent === null) {
            const agentId: string | undefined | null = Storage.getItem<string>(Constants.AGENT_ID_KEY);

            if (agentId) {
               await fetchAgent(agentId);
            } else {
               setReferrer(getPath(location));
               state = AppState.UNAUTHENTICATED;
            }
         }

         setAppState(state);
      }

      updateAuth();

      const tokenSub = EventEmitter.on(Events.TOKEN_ACQUIRED, updateAuth);
      const logoutSub = EventEmitter.on(Events.USER_LOGGED_OUT, updateAuth);

      return () => {
         tokenSub.unsubscribe();
         logoutSub.unsubscribe();
      };
   }, []);

   React.useEffect(() => {
      if (appState !== AppState.INITIAL) {
         if (appState === AppState.AUTHENTICATED) {
            if (referrer && referrer !== Routes.LOGIN) {
               navigate(referrer);
               setReferrer(null);
            } else if (location.pathname === Routes.LOGIN) {
               navigate(Routes.ROOT);
            }
         } else {
            if (storeReferrer) {
               setReferrer(getPath(location));
            }
            setStoreReferrer(true);
            clearAuth();
         }
      }
   }, [appState]); // eslint-disable-line react-hooks/exhaustive-deps

   if (appState === AppState.INITIAL) {
      return <OrbitalProgress overlay />;
   } else if (location.pathname.includes('password')) {
      return <PublicRoutes />;
   } else if (appState === AppState.AUTHENTICATED) {
      return <AuthRoutes />;
   } else {
      return <PublicRoutes />;
   }
}

export default AppRoutes;
export { AppRoutes };

import React, { PropsWithChildren } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import clsx from 'clsx';
import {
   NavigationStore,
   SettingsStore,
   useNavigationStore,
   UserStore,
   useSettingsStore,
   usePersistentToolsDrawerStore,
   useUserStore,
   PersistentToolsDrawerStore,
   useToolsDrawerStore,
   ToolsDrawerStore,
   useMemberStore,
   MemberStore,
} from '../store';
import * as H from 'history';
import { useLocation } from 'react-router-dom';
import { getEnv } from '../utils/env';
import { isNamespace, Namespaces } from '../theme';
import { Routes } from '../routes/Routes';
import { NavigationDrawer, ToolbarLeft, ToolbarRight } from '../components/layout';
import { BaseLayout } from './BaseLayout';
import { SplitPane } from '../components/splitPane';
import { Instructions } from '../components/instructions';
import styles from './ThemedLayout.module.scss';
import { useFetchLighthouseTags } from '../store/actions';
import { LighthouseTagGroup } from '../types';

export interface ThemedLayoutProps extends PropsWithChildren<any> {}

const env = getEnv();

const membersRegExp = new RegExp(`${Routes.CAFE_MEMBERS}/+.`, 'ig');
const cafeRegExp = new RegExp(`/cafe/.+`, 'i');

const ThemedLayout = React.memo(({ children }: ThemedLayoutProps): JSX.Element => {
   const [namespace, setNamespace] = React.useState<Namespaces | null>(null);
   const [asideChildren, setAsideChildren] = React.useState<React.ReactElement | null>(null);

   const [isCafePage, setIsCafePage] = React.useState(false);

   const navigationDrawerOpened = useSettingsStore((state: SettingsStore) => state.navigationDrawerOpened);
   const switchNavigationDrawer = useSettingsStore((state: SettingsStore) => state.switchNavigationDrawer);

   const toggleToolsDrawer = usePersistentToolsDrawerStore(
      (state: PersistentToolsDrawerStore) => state.toggleToolsDrawer,
   );
   const showInstructions = useToolsDrawerStore((state: ToolsDrawerStore) => state.showInstructions);
   const instructionsId = useToolsDrawerStore((state: ToolsDrawerStore) => state.instructionsId);

   const shouldShowInstructions = useSettingsStore((state: SettingsStore) => state.showInstructions);
   const agent = useUserStore((state: UserStore) => state.agent);
   const lighthouseTags = useMemberStore((state: MemberStore) => state.lighthouseTags);

   const activeRoute = useNavigationStore((state: NavigationStore) => state.activeRoute);

   const location: H.Location = useLocation();
   const { fetchLighthouseTags } = useFetchLighthouseTags();

   React.useEffect(() => {
      if (lighthouseTags === null || lighthouseTags.records.length === 0) {
         fetchLighthouseTags(1, 100, [LighthouseTagGroup.BRIDGE_CAFE, LighthouseTagGroup.LABS]);
      }
   }, []);

   React.useEffect(() => {
      if (instructionsId && shouldShowInstructions) {
         setAsideChildren(<Instructions id={instructionsId} onClose={showInstructions.bind(null, null)} />);
      } else {
         setAsideChildren(null);
      }
   }, [instructionsId]);

   React.useEffect(() => {
      if (!membersRegExp.test(location.pathname)) {
         showInstructions(null);
         toggleToolsDrawer(false);
      }
      setIsCafePage(cafeRegExp.test(location.pathname));
   }, [location.pathname]);

   React.useEffect(() => {
      if (env !== 'production') {
         document.title = `${document.title} [${env.toUpperCase()}]`;
      }
   }, []);

   React.useEffect(() => {
      // @ts-ignore
      setNamespace(location.pathname.split('/')[1]);
   }, [location.pathname]);

   function handleSignOut() {}

   return (
      <BaseLayout>
         <div className={styles.root}>
            {isNamespace(namespace) && (
               <AppBar className={clsx(styles.app_bar, navigationDrawerOpened && styles.app_bar_shift)} elevation={0}>
                  <Toolbar className={styles.toolbar}>
                     <ToolbarLeft
                        onMenuClick={switchNavigationDrawer}
                        drawerOpened={navigationDrawerOpened}
                        activeRoute={activeRoute}
                     />
                     <ToolbarRight onSignOut={handleSignOut} isCafePage={isCafePage} />
                  </Toolbar>
               </AppBar>
            )}

            <NavigationDrawer open={navigationDrawerOpened} agent={agent} />

            <SplitPane sizes={[65, 35]} direction="horizontal">
               <main className={styles.content}>
                  <div className={styles.app_bar_spacer} />
                  {children}
                  <div id="workflow-root" />
               </main>
               {asideChildren && (
                  <aside className={styles.content}>
                     <div className={styles.app_bar_spacer} />
                     {asideChildren}
                  </aside>
               )}
            </SplitPane>
         </div>
      </BaseLayout>
   );
});

export default ThemedLayout;
export { ThemedLayout };

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';

import './index.css';
import { getEnv, getSentryDsn } from './utils/env';

const env = getEnv();

Sentry.init({
   dsn: getSentryDsn(),
   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
   environment: env,
   enabled: false,
   // Tracing
   tracesSampleRate: 1.0, //  Capture 100% of the transactions
   // Session Replay
   replaysSessionSampleRate: env === 'production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
   <React.StrictMode>
      <App />
   </React.StrictMode>,
);

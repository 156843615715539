import { create } from 'zustand';
import { PaginatedResponse } from '../services';
import { BatchWorkflow } from '../types';

interface BatchWorkflowsStoreData {
   workflows: PaginatedResponse<BatchWorkflow> | null;
   workflow: BatchWorkflow | null;
   queue: BatchWorkflow[];
   dryRunResult: any | null;
   submission: boolean;
}

interface BatchWorkflowsStoreActions {
   clear: () => void;

   setWorkflows: (workflows: PaginatedResponse<BatchWorkflow> | null) => void;
   setWorkflow: (workflow: BatchWorkflow | null) => void;
   setQueue: (queue: BatchWorkflow[]) => void;
   setDryRunResult: (dryRunResult: any | null) => void;
   addWorkflowToQueue: (workflow: BatchWorkflow) => void;
   removeWorkflowFromQueue: (workflow: BatchWorkflow) => void;
   setSubmission: (submission: boolean) => void;
}

export type BatchWorkflowsStore = BatchWorkflowsStoreData & BatchWorkflowsStoreActions;

const initialState: BatchWorkflowsStoreData = {
   workflows: null,
   workflow: null,
   queue: [],
   dryRunResult: null,
   submission: false,
};

export const useBatchWorkflowsStore = create<BatchWorkflowsStore>()(
   (set, get): BatchWorkflowsStore => ({
      ...initialState,

      clear: () => set({ ...initialState }),
      setWorkflows: (workflows: PaginatedResponse<BatchWorkflow> | null) => set({ workflows }),
      setWorkflow: (workflow: BatchWorkflow | null) => set({ workflow }),
      setQueue: (queue: BatchWorkflow[]) => set({ queue }),
      setDryRunResult: (dryRunResult: any | null) => set({ dryRunResult }),
      addWorkflowToQueue: (workflow: BatchWorkflow) => {
         const queue = get().queue.slice();
         queue.push(workflow);
         set({ queue });
      },
      removeWorkflowFromQueue: (workflow: BatchWorkflow) => {
         const queue = get().queue.filter((w: BatchWorkflow) => w.id !== workflow.id);
         set({
            queue,
         });
      },
      setSubmission: (submission: boolean) => set({ submission }),
   }),
);

import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { Customer } from 'types';

import styles from './CustomerListItem.module.scss';

export interface CustomerListItemProps {
   customer: Customer;
   onClick: (customerId: string) => void;
}

function CustomerListItem({ customer, onClick }: CustomerListItemProps): JSX.Element {
   return (
      <ListItem button onClick={onClick.bind(null, customer.id)}>
         <ListItemIcon>
            <PersonIcon />
         </ListItemIcon>
         <ListItemText
            primary={`${customer.first_name} ${customer.last_name}`}
            primaryTypographyProps={{
               className: styles.title,
            }}
            secondaryTypographyProps={{
               component: 'div',
            }}
            secondary={
               <>
                  <Typography variant="body2">{customer.phone}</Typography>
                  <Typography variant="body2">{customer.email}</Typography>
               </>
            }
         />
      </ListItem>
   );
}

export default React.memo(CustomerListItem);

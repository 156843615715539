import { createTheme as createMuiTheme } from '@mui/material/styles';

let create = createMuiTheme;

const primaryColor = '#FFC8D0';
const secondaryColor = '#FFD036';

export const createTheme = (type: 'light' | 'dark' = 'dark') => {
   const specific: any = {
      palette: {},
   };

   if (type === 'light') {
      specific.palette = {
         background: {
            default: '#f2f2f2',
            paper: '#fff',
         },
         secondary: {
            main: '#515151',
         },
      };
   } else {
      specific.palette = {
         background: {
            default: '#1a1c1e',
            paper: '#1a1c1e',
         },
         secondary: {
            main: '#515151',
         },
      };
   }

   return create({
      palette: {
         mode: type,
         primary: {
            main: primaryColor,
         },
         secondary: {
            main: secondaryColor,
         },
         ...specific.palette,
         error: {
            main: '#f44336',
         },
         warning: {
            main: '#ff9800',
         },
         success: {
            main: '#4caf50',
         },
         info: {
            main: '#06BCEE',
         },
      },
      components: {
         MuiDialog: {
            styleOverrides: {
               paper: `
                  background-image: none;
               `,
            },
         },
         MuiCssBaseline: {
            styleOverrides: `
               *::-webkit-scrollbar {
                  width: 0.4em;
               }
                  
               *::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
               }
               
               *::-webkit-scrollbar-thumb {
                  background-color: rgba(0, 0, 0, .1);
               }
            
               body {
                  background-color: '#18191a';
               }
            `,
         },
      },
      typography: {
         allVariants: {
            letterSpacing: 0.5,
         },
      },
   });
};

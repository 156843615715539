export enum LighthouseTagGroup {
   BRIDGE_CAFE = 'bridge_cafe',
   LABS = 'labs',
}

export interface TagBase {
   id: string;
   name: string;
}

export type LighthouseTag = TagBase & {
   active: boolean;
   owner?: string;
   group?: LighthouseTagGroup;
   created_at: string;
   updated_at: string;
};

import { isEmpty } from '@bridgemoney/core';

const regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);

export const isPhoneNumberValid = (phone: string = ''): boolean => {
   if (isEmpty(phone)) {
      return false;
   }

   return regexp.test(phone);
};

export const normalizePhoneNumber = (text: string): string => {
   if (isPhoneNumber(text)) {
      let normalized = text
         .replaceAll('(', '')
         .replaceAll(')', '')
         .replaceAll(' ', '')
         .replaceAll('-', '')
         .replaceAll('.', '');

      if (!normalized.startsWith('+')) {
         if (!normalized.startsWith('1')) {
            normalized = '1' + normalized;
         }
         normalized = '+' + normalized;
      }

      return normalized;
   }

   return text;
};

const isPhoneNumber = (text: string): boolean => {
   if (new RegExp(/[(]{0,1}[0-9]{1,3}[)](\d)?[-\s./0-9]*$/g).test(text)) {
      return true;
   } else if (new RegExp(/(1)?(-?[0-9]{3}){2}-[0-9]{4}/g).test(text)) {
      return true;
   } else if (new RegExp(/([0-9]{3}\.){2}[0-9]{4}/g).test(text)) {
      return true;
   }

   return false;
};

import { useTask } from '../useTask';
import { useInstance } from '@bridgemoney/core';
import { LighthouseTagsService } from '../../../services';
import { MemberStore, useMemberStore } from '../../MemberStore';
import { Constants } from '../../../constants';
import { LighthouseTagGroup } from '../../../types';

export interface UseFetchLighthouseTags {
   fetchLighthouseTags: (page: number, page_size: number, groups: LighthouseTagGroup[]) => Promise<boolean>;
}

export function useFetchLighthouseTags(): UseFetchLighthouseTags {
   const task = useTask();

   const setLighthouseTags = useMemberStore((state: MemberStore) => state.setLighthouseTags);
   const service = useInstance<LighthouseTagsService>('LighthouseTagsService');

   async function fetchLighthouseTags(
      page: number = 1,
      page_size: number = Constants.DEFAULT_PAGE_SIZE,
      groups: LighthouseTagGroup[] = [],
   ): Promise<boolean> {
      return await task.run(async () => {
         const res = await service.fetchTags(page, page_size, groups, [], [true]);

         if (!res.error && res.data) {
            setLighthouseTags(res.data);
         }
      });
   }

   return {
      fetchLighthouseTags,
   };
}

import React from 'react';

export interface UseTimeout {
   timeout: (action?: () => void, timeout?: number) => void;
   clearTimeout: () => void;
}

export function useTimeout(): UseTimeout {
   const timerRef = React.useRef<any>(0);

   function timeout(action?: () => void, timeout: number = 500) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
         clearTimeout(timerRef.current);
         if (action) {
            action();
         }
      }, timeout);
   }

   function stop() {
      clearTimeout(timerRef.current);
   }

   return {
      timeout,
      clearTimeout: stop,
   };
}

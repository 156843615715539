import { create } from 'zustand';

export type Severity = 'success' | 'info' | 'warning' | 'error';

export interface ToastStore {
   message: string | null;
   helperText: string | null;
   severity: Severity | null;

   setMessage: (message: string | null, severity: Severity | null) => void;
   setHelperText: (helperText: string | null) => void;
}

export const useToastStore = create<ToastStore>()(
   (set, get): ToastStore => ({
      message: null,
      severity: null,
      helperText: null,

      setHelperText: (helperText: string | null) => set({ helperText }),
      setMessage: (message: string | null, severity: Severity | null = 'success') => set({ message, severity }),
   }),
);

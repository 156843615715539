import { create } from 'zustand';
import { Agent, LighthouseRole } from 'types';
import { SignInError, PasswordResetValidationError } from '../services';

interface UserStoreData {
   agent: Agent | null;
   agents: Agent[];
   provisioningUri: string | null;
   resetPasswordError: PasswordResetValidationError | null;
   roles: LighthouseRole[];
   signInError: SignInError | null;
}

interface UserStoreActions {
   clear: () => void;
   setAgent: (agent: Agent | null) => void;
   setAgents: (agents: Agent[]) => void;
   setProvisioningUri: (provisioningUri: string | null) => void;
   setResetPasswordError: (resetPasswordError: PasswordResetValidationError | null) => void;
   setRoles: (roles: LighthouseRole[]) => void;
   setSignInError: (signInError: SignInError | null) => void;
}

export type UserStore = UserStoreData & UserStoreActions;

const initialState: UserStoreData = {
   agent: null,
   agents: [],
   provisioningUri: null,
   resetPasswordError: null,
   roles: [],
   signInError: null,
};

export const useUserStore = create<UserStore>()(
   (set, get): UserStore => ({
      ...initialState,

      clear: () => set({ ...initialState }),

      setAgent: (agent: Agent | null) => set({ agent }),
      setAgents: (agents: Agent[]) => set({ agents }),
      setProvisioningUri: (provisioningUri: string | null) => set({ provisioningUri }),
      setResetPasswordError: (resetPasswordError: PasswordResetValidationError | null) => set({ resetPasswordError }),
      setRoles: (roles: LighthouseRole[]) => set({ roles }),
      setSignInError: (signInError: SignInError | null) => set({ signInError }),
   }),
);

export class Executor {
   static execRepeatedly(fn: () => void, interval: number): number {
      return window.setInterval(fn, interval);
   }

   static execOnce(fn: () => void, delay: number): number {
      return window.setTimeout(fn, delay);
   }

   static stopRepetition(pid: number): void {
      window.clearInterval(pid);
   }

   static stop(pid: number): void {
      window.clearTimeout(pid);
   }
}

export const capitalize = (str: string): string => {
   if (str) {
      return str.substring(0, 1).toUpperCase() + str.substring(1, str.length);
   }

   return '';
};

export async function copyToClipboard(text: string): Promise<void> {
   await navigator.clipboard.writeText(text);
}

export function highlightTextInElement(element: HTMLElement | null, text: string, highlightClassName: string): void {
   if (element) {
      const content = element.innerText;
      const contentLowerCase = content.toLowerCase();
      const searchLowerCase = text.toLowerCase();

      const indexOfSearch = contentLowerCase.indexOf(searchLowerCase);
      if (indexOfSearch !== -1) {
         const original = content.substring(indexOfSearch, indexOfSearch + searchLowerCase.length);

         element.innerHTML =
            content.substring(0, indexOfSearch) +
            `<span class="${highlightClassName}">${original}</span>` +
            content.substring(indexOfSearch + searchLowerCase.length);
      } else {
         element.innerHTML = content;
      }
   }
}

import React from 'react';
import { Executor } from '../services';

function stop(pid: number, repeatedly: boolean): void {
   if (repeatedly) {
      Executor.stopRepetition(pid);
   } else {
      Executor.stop(pid);
   }
}

function start(fn: () => void, delay: number, repeatedly: boolean = false): number {
   if (repeatedly) {
      return Executor.execRepeatedly(fn, delay);
   } else {
      return Executor.execOnce(fn, delay);
   }
}

export function useExecutor(fn: () => void, delay: number, repeatedly: boolean = false) {
   const pidRef = React.useRef<number>(0);

   React.useEffect(() => {
      stop(pidRef.current, repeatedly);
      pidRef.current = start(fn, delay, repeatedly);

      return () => {
         stop(pidRef.current, repeatedly);
      };
   }, [delay, repeatedly]);

   return [pidRef.current];
}

import { MainStore, useMainStore } from '../MainStore';
import { ToastStore, useToastStore } from '../ToastStore';

export interface UseTask {
   run: (
      task: () => Promise<any>,
      showError?: boolean,
      onError?: (e: any) => void,
      onFinally?: () => void,
   ) => Promise<boolean>;
}

export function useTask(): UseTask {
   const setLoading = useMainStore((state: MainStore) => state.setLoading);
   const toast = useToastStore((state: ToastStore) => ({
      setMessage: state.setMessage,
      setHelperText: state.setHelperText,
   }));

   async function run(
      task: () => any,
      showError: boolean = true,
      onError?: (e: any) => void,
      onFinally?: () => void,
   ): Promise<boolean> {
      let res: boolean = false;

      try {
         setLoading(true);
         const result = await task();

         if (result !== undefined) {
            res = result;
         } else {
            res = true;
         }
      } catch (e: any) {
         console.error(e);

         if (showError) {
            const message: string = e.error ? e.error : e.message;
            toast.setMessage(message, 'error');

            if (e.data) {
               toast.setHelperText(JSON.stringify(e.data, null, 2));
            }
         }

         if (onError) {
            onError(e);
         }
      } finally {
         setLoading(false);

         if (onFinally) {
            onFinally();
         }
      }

      return res;
   }

   return {
      run,
   };
}

export interface UseEscape {
   listen: () => void;
   clear: () => void;
}

export function useEscape(handler?: () => void): UseEscape {
   function handleEscape(e: KeyboardEvent) {
      if ((e.key === 'Escape' || e.code === 'Escape') && handler) {
         handler();
      }
   }

   function listen() {
      document.addEventListener('keyup', handleEscape);
   }

   function clear() {
      document.removeEventListener('keyup', handleEscape);
   }

   return {
      listen,
      clear,
   };
}

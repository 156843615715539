import React from 'react';

import styles from './ErrorPage.module.scss';
import { Button, Container, Paper } from '../components/mui';
import { Collapse, Typography } from '@mui/material';

interface ErrorPageProps {
   error: string;
   stack: any;
   resetErrorBoundary: () => void;
}

const ErrorPage = React.memo(({ error, stack, resetErrorBoundary }: ErrorPageProps): JSX.Element => {
   const [open, setOpen] = React.useState(false);

   function toggleStack() {
      if (open) {
         setOpen(false);
      } else {
         setOpen(true);
      }
   }

   return (
      <Container role="alert" maxWidth={open ? 'md' : 'sm'}>
         <Paper className={styles.paper} elevation={4}>
            <Typography>Something went wrong:</Typography>
            <pre className={styles.error_message}>{error}</pre>

            <Collapse in={open} timeout="auto" unmountOnExit>
               <pre className={styles.error_message}>{stack}</pre>
            </Collapse>

            <div className={styles.footer}>
               <Button variant="outlined" color="secondary" onClick={toggleStack}>
                  {open ? 'Hide' : 'Show'} Details
               </Button>
               <Button variant="contained" onClick={resetErrorBoundary}>
                  Try again
               </Button>
            </div>
         </Paper>
      </Container>
   );
});

export { ErrorPage };
export default ErrorPage;

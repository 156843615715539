import { Request } from '@bridgemoney/core';

export class AbstractService {
   protected request: Request;

   /**
    * @param {Request} request
    */
   constructor(request: Request) {
      this.request = request;
   }
}

import { AbstractService } from './AbstractService';
import { ServiceResponse } from './responses';
import { Config, ConfigFull } from 'types/syscon';

const PREFIX = `/crm/configs`;

export enum ConfigGroup {
   DEFAULT = 'default',
   DAILY_DROP = 'daily_drop',
   CASH_OUTS = 'cash_outs',
   GOLD_SURVEYS = 'gold_surveys',
}

export class ConfigsService extends AbstractService {
   fetchConfigs(group: ConfigGroup = ConfigGroup.DEFAULT): Promise<ServiceResponse<Config[], any>> {
      return this.request.get({
         url: PREFIX,
         data: {
            group,
         },
         signed: true,
      });
   }

   fetchConfig(id: string): Promise<ServiceResponse<ConfigFull, any>> {
      return this.request.get({
         url: `${PREFIX}/${id}`,
         signed: true,
      });
   }

   updateConfig(id: string, value: string, description: string): Promise<ServiceResponse<Config, any>> {
      return this.request.put({
         url: `${PREFIX}/${id}`,
         data: {
            value,
            description,
         },
         signed: true,
      });
   }
}

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import { Logger } from '@bridgemoney/core';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const logger = new Logger('DateFormatter');

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY, h:mm A';
export const DEFAULT_TIME_FORMAT = 'MM/DD/YYYY, HH:mm:ssZ[Z]';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';

dayjs.extend(AdvancedFormat);

export const dateFormat = (date: any, format: string = DEFAULT_DATE_FORMAT): string => {
   try {
      const res = dayjs(date).format(format);

      return res.toLowerCase() === 'invalid date' ? 'N/A' : res;
   } catch (e) {
      logger.error(e);

      return date;
   }
};

export const timeFormat = (date: any, format: string = DEFAULT_TIME_FORMAT): string => {
   return dateFormat(date, format);
};

export const localDatetimeToServerDatetime = (datetime: any, format: string = 'YYYY-MM-DDTHH:mm'): string => {
   // previous CST tz name was "America/Swift_Current"
   return dayjs(datetime).tz('America/Chicago').format(format).toString();
};

export const localDatetimeToServerUnixTimestamp = (datetime: any): number => {
   // previous CST tz name was "America/Swift_Current"
   return dayjs(datetime).tz('America/Chicago').valueOf();
};

export const isDateToday = (date: any): boolean => {
   return dateFormat(new Date(date), 'MM/DD/YYYY') === dateFormat(new Date().toLocaleDateString(), 'MM/DD/YYYY');
};

import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { Button } from '../../mui';

import clsx from 'clsx';
import { ConfirmationSeverity, ConfirmationStore, useConfirmationStore } from '../../../store';

import styles from './ConfirmationDialog.module.scss';

interface IconProps {
   className: string;
}

const Icon = React.memo(({ className }: IconProps) => {
   return (
      <div className={clsx(styles.icon, className)}>
         <div className={styles.line} />
         <div className={styles.line} />
      </div>
   );
});

export interface ConfirmationDialogProps {
   description?: string;
   icon?: ConfirmationSeverity | null;
   onAction: (result: boolean) => void;
   open: boolean;
   title?: string;
}

const ConfirmationDialog = React.memo(
   ({
      description = '',
      icon = ConfirmationSeverity.SUCCESS,
      onAction,
      open,
      title = '',
   }: ConfirmationDialogProps): JSX.Element => {
      const confirmation = useConfirmationStore((state: ConfirmationStore) => ({
         toggle: state.toggle,
      }));

      function handleAction(result: boolean) {
         confirmation.toggle(false);
         onAction(result);
      }

      return (
         <Dialog open={open} fullWidth>
            <div className={styles.iconWrapper}>
               <Icon className={styles[icon || ConfirmationSeverity.SUCCESS]} />
            </div>

            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
               <DialogContentText>
                  <Typography dangerouslySetInnerHTML={{ __html: description }} />
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleAction.bind(null, false)} color="secondary">
                  Cancel
               </Button>
               <Button variant="contained" onClick={handleAction.bind(null, true)} color="primary" autoFocus>
                  Confirm
               </Button>
            </DialogActions>
         </Dialog>
      );
   },
);

export { ConfirmationDialog };
export default ConfirmationDialog;

import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Container.module.scss';

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
   component?: any;
   disableGutters?: boolean;
   fixed?: boolean;
   maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const maxWidthClassesMap: any = {
   xs: styles.maxWidthXs,
   sm: styles.maxWidthSm,
   md: styles.maxWidthMd,
   lg: styles.maxWidthLg,
   xl: styles.maxWidthXl,
};

const Container = React.memo(
   ({
      className,
      component = 'div',
      children,
      disableGutters,
      fixed,
      maxWidth = 'lg',
      ...rest
   }: ContainerProps): JSX.Element => {
      const Component = component;

      const maxWidthClass = React.useMemo(() => {
         if (maxWidth !== undefined) {
            if (maxWidth) {
               return maxWidthClassesMap[maxWidth];
            }

            return '';
         }

         return maxWidthClassesMap.lg;
      }, [maxWidth]);

      return (
         <Component
            className={clsx(styles.container, disableGutters ? styles.disableGutters : '', maxWidthClass, className)}
            {...rest}
         >
            {children}
         </Component>
      );
   },
);

export { Container };
export default Container;

import React from 'react';
import { Logger } from '@bridgemoney/core';

import { useServiceInitialization } from './hooks';
import { ToastStore, useToastStore } from './store';
import { getApiUrl, getEnv, isMock, isProduction } from './utils/env';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from './theme';
import { AppRoutes } from './routes';
import { ErrorPage } from './pages';
import * as Sentry from '@sentry/react';

Logger.enabled(!isProduction());

const apiUrl = getApiUrl();
if (!apiUrl) {
   throw new Error(`You must provide VITE_API_URL env variable!`);
}

let platform: string = '';
// @ts-ignore
if (window.navigator.userAgentData) {
   // @ts-ignore
   platform = window.navigator.userAgentData.platform;
} else {
   platform = window.navigator.platform;
}

function App() {
   const toast = useToastStore((state: ToastStore) => ({
      severity: state.severity,
      message: state.message,
      setMessage: state.setMessage,
   }));

   const [initialized] = useServiceInitialization(
      platform,
      apiUrl || '',
      isMock(),
      (error: string) => {
         toast.setMessage(error, 'error');
      },
      getEnv(),
   );

   const theme: 'light' | 'dark' = 'dark';
   const muiTheme = React.useMemo(() => createTheme(theme), [theme]);

   React.useEffect(() => {
      document.body.style.setProperty('--bui-background', `var(--bui-background-${theme})`);
      document.body.style.setProperty('--bui-background-darker', `var(--bui-background-darker-${theme})`);
      document.body.style.setProperty('--bui-background-secondary', `var(--bui-background-secondary-${theme})`);
      document.body.style.setProperty('--bui-disabled-background', `var(--bui-disabled-background-${theme})`);
      document.body.style.setProperty('--bui-font-color', `var(--bui-font-color-${theme})`);
      document.body.style.setProperty('--bui-disabled-font-color', `var(--bui-disabled-font-color-${theme})`);
      document.body.style.setProperty('--bui-divider-color', `var(--bui-divider-color-${theme})`);
      document.body.style.setProperty('--bui-divider-color-secondary', `var(--bui-divider-color-secondary-${theme})`);
      document.body.style.setProperty('--bui-font-color-secondary', `var(--bui-font-color-secondary-${theme})`);
      document.body.style.setProperty('--bui-icon-inversion', `var(--bui-icon-inversion-${theme})`);
      document.body.style.setProperty('--bui-link', `var(--bui-link-${theme})`);
      document.body.style.setProperty('--bui-mini-header-font-color', `var(--bui-mini-header-font-color-${theme})`);
   }, [theme]);

   return (
      <BrowserRouter>
         <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Sentry.ErrorBoundary
               fallback={({ error, componentStack, resetError }) => {
                  return (
                     <ErrorPage
                        error={error ? error.toString() : 'Error'}
                        stack={componentStack}
                        resetErrorBoundary={resetError}
                     />
                  );
               }}
            >
               {initialized ? <AppRoutes /> : <React.Fragment />}
            </Sentry.ErrorBoundary>
         </ThemeProvider>
      </BrowserRouter>
   );
}

export default App;

import React from 'react';
import IconButtonBase, { IconButtonBaseProps } from './IconButtonBase';

export interface CopyButtonProps {}

const CopyButton = React.memo(({ ...rest }: CopyButtonProps & IconButtonBaseProps): JSX.Element => {
   return (
      <IconButtonBase {...rest}>
         <img src="/icons/copy.png" alt="copy button" />
      </IconButtonBase>
   );
});

export { CopyButton };
export default CopyButton;

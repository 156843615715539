import { create } from 'zustand';
import { Route } from 'routes/Routes';

export interface NavigationStore {
   activeRoute: Route | null;
   setActiveRoute: (activeRoute: Route) => void;
}

export const useNavigationStore = create<NavigationStore>()(
   (set, get): NavigationStore => ({
      activeRoute: null,
      setActiveRoute: (activeRoute: Route) => set({ activeRoute }),
   }),
);

import { GetState, SetState } from 'zustand';
import { SettingsStore } from 'store';

export function switchTheme(set: SetState<SettingsStore>, get: GetState<SettingsStore>): void {
   let theme: 'light' | 'dark';

   if (get().theme === 'dark') {
      theme = 'light';
   } else {
      theme = 'dark';
   }

   set({ theme });
}

import { GetState, SetState } from 'zustand';
import { PersistentToolsDrawerStore } from 'store';

export function toggleToolsDrawer(
   set: SetState<PersistentToolsDrawerStore>,
   get: GetState<PersistentToolsDrawerStore>,
   opened?: boolean | undefined,
): void {
   if (opened !== undefined) {
      set({ opened: opened });
   } else {
      set({ opened: !get().opened });
   }
}

import React from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import styles from './InfoBlock.module.scss';
import { CopyToClipboardButton } from '../common/buttons';

export interface InfoBlockProps extends BoxProps {
   caption: string;
   description?: string | React.ReactNode | (() => React.ReactNode);
   captionProps?: TypographyProps;
   descriptionProps?: TypographyProps;
   valueToCopy?: string;
   containerClassName?: string;
}

const InfoBlock = React.memo(
   ({
      caption,
      description,
      descriptionProps,
      captionProps,
      className,
      valueToCopy,
      children,
      containerClassName = '',
      ...rest
   }: InfoBlockProps): JSX.Element => {
      function renderDescription() {
         if (description) {
            if (typeof description === 'function') {
               return description();
            }

            return (
               <Typography paragraph {...descriptionProps}>
                  {description}
               </Typography>
            );
         }

         return null;
      }

      return (
         <Box className={clsx(styles.root, className)} {...rest}>
            <div className={containerClassName}>
               <Typography variant="body2" className={styles.caption} {...captionProps}>
                  {caption}
               </Typography>
               {renderDescription()}
            </div>
            {valueToCopy ? <CopyToClipboardButton className={styles.copy_button} value={valueToCopy} /> : null}
            {children}
         </Box>
      );
   },
);

export default InfoBlock;
export { InfoBlock };

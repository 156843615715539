import React from 'react';
import { copyToClipboard } from '../../../utils';
import { ToastStore, useToastStore } from '../../../store';
import { CopyButton } from './CopyButton';
import { IconButtonBaseProps } from './IconButtonBase';

export interface CopyToClipboardButtonProps {
   value: string;
}

const CopyToClipboardButton = React.memo(
   ({ value, ...rest }: CopyToClipboardButtonProps & IconButtonBaseProps): JSX.Element => {
      const toast = useToastStore((state: ToastStore) => ({
         setMessage: state.setMessage,
      }));

      async function handleCopy() {
         try {
            await copyToClipboard(value);
            toast.setMessage(`Copied ${value}!`, 'success');
         } catch (e: any) {
            toast.setMessage(e.message, 'error');
         }
      }

      return <CopyButton size="small" onClick={handleCopy} {...rest} />;
   },
);

export default CopyToClipboardButton;
export { CopyToClipboardButton };

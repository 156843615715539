import React from 'react';
import { InstructionsService } from '../../services';
import { InstructionsBlock, InstructionsObjectType } from '../../types';
import { isEmpty, useInstance } from '@bridgemoney/core';
import { useEscape, useRequest } from '../../hooks';
import { IconButton } from '@mui/material';
import Text from './Text';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { RowBox } from '../common';
import { CircularProgress } from '../mui';
import styles from './Instructions.module.scss';

export interface InstructionsProps {
   id?: string;
   onClose?: () => void;
}

const typeToElementMap = new Map<InstructionsObjectType, string>();
typeToElementMap.set('heading_1', 'h1');
typeToElementMap.set('heading_2', 'h2');
typeToElementMap.set('heading_3', 'h3');
typeToElementMap.set('heading_4', 'h4');
typeToElementMap.set('heading_5', 'h5');
typeToElementMap.set('heading_6', 'h6');
typeToElementMap.set('paragraph', 'p');
typeToElementMap.set('bulleted_list_item', 'li');
typeToElementMap.set('numbered_list_item', 'li');
typeToElementMap.set('unsupported', 'div');

const isListItem = (block: InstructionsBlock): boolean => {
   return block.type === 'bulleted_list_item' || block.type === 'numbered_list_item';
};

const getListElementForBlock = (block: InstructionsBlock): string => {
   return block.type === 'bulleted_list_item' ? 'ul' : 'ol';
};

const Instructions = React.memo(({ id, onClose }: InstructionsProps): JSX.Element => {
   const [instructions, setInstructions] = React.useState<InstructionsBlock[] | null>([]);

   const service: InstructionsService = useInstance<InstructionsService>('InstructionsService');
   const fetchInstructions = useRequest<InstructionsBlock[]>(service.fetch.bind(service, id || ''));
   const refreshInstructions = useRequest<InstructionsBlock[]>(service.refresh.bind(service, id || ''));

   const escapeHandler = useEscape(onClose);

   React.useEffect(() => {
      escapeHandler.listen();

      return () => escapeHandler.clear();
   }, []);

   React.useEffect(() => {
      if (id) {
         fetchInstructions.run();
      }
   }, [id]);

   React.useEffect(() => {
      setInstructions(fetchInstructions.data);
   }, [fetchInstructions.data]);

   React.useEffect(() => {
      setInstructions(refreshInstructions.data);
   }, [refreshInstructions.data]);

   function renderBlocks(blocks: InstructionsBlock[]): JSX.Element[] {
      const children: any[] = [];
      let list: React.DOMElement<any, any> | null = null;
      let listPosition: number | undefined;

      blocks.forEach((block: InstructionsBlock, index: number) => {
         if (isListItem(block)) {
            if (list === null) {
               list = React.createElement(
                  getListElementForBlock(block),
                  {
                     key: `list_${block.id}`,
                  },
                  [renderBlock(block, index)],
               );

               listPosition = children.push(list) - 1;
            } else {
               list = React.cloneElement(list, list.props, [...list.props.children, renderBlock(block, index)]);

               if (listPosition) {
                  children.splice(listPosition, 1, list);
               }
            }
         } else {
            list = null;
            listPosition = undefined;

            children.push(renderBlock(block, index));
         }
      });

      return children;
   }

   function renderRefreshButton(): JSX.Element {
      return (
         <IconButton title="Refresh Instructions" onClick={refreshInstructions.run} size="small">
            <RefreshIcon />
         </IconButton>
      );
   }

   function renderCloseButton(): JSX.Element | null {
      if (onClose) {
         return (
            <IconButton title="Close Instructions" onClick={onClose} size="small">
               <CloseIcon />
            </IconButton>
         );
      }

      return null;
   }

   function renderBlock(block: InstructionsBlock, index: number, element?: string): JSX.Element {
      // @ts-ignore
      const blockType: any = block[block.type];

      // @ts-ignore
      return React.createElement(
         element ? element : typeToElementMap.get(block.type) || 'div',
         {
            key: `${block.id}_${index}`,
            className: styles[block.type] || '',
         },
         [<Text text={blockType.text} key={`${block.id}_${index}_${block.type}`} />, renderBlocks(block.children)],
      );
   }

   if (fetchInstructions.loading || refreshInstructions.loading) {
      return <CircularProgress size={20} />;
   }

   if (instructions && !isEmpty(instructions)) {
      return (
         <div className={styles.root}>
            <RowBox className={styles.refreshButtonContainer}>
               {renderRefreshButton()}
               {renderCloseButton()}
            </RowBox>

            {renderBlocks(instructions)}
         </div>
      );
   }

   return <div />;
});

export default Instructions;
export { Instructions };

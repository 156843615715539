import React, { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import styles from './NoData.module.scss';

export interface NoDataProps extends PropsWithChildren<any> {
   title?: string;
}

const NoData = React.memo(({ title = 'No Data', className = '' }: NoDataProps): JSX.Element => {
   return (
      <Typography color="textPrimary" className={clsx(styles.root, className)}>
         {title}
      </Typography>
   );
});

export default NoData;
export { NoData };

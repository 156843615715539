import React from 'react';
import { ServiceResponse } from '../services';
import { ToastStore, useToastStore } from '../store';

export interface UseRequest<T> {
   loading: boolean;
   error: string | null;
   run: () => void;
   clear: () => void;
   data: T;
   toastMessage: string | null;
}

/**
 * @deprecated
 * @param fn
 */
export function useRequest<T>(fn: () => any): UseRequest<T | null> {
   const [loading, setLoading] = React.useState<boolean>(false);
   const [error, setError] = React.useState<string | null>(null);
   const [data, setData] = React.useState<T | null>(null);
   const [toastMessage, setToastMessage] = React.useState<string | null>(null);

   const toast = useToastStore((state: ToastStore) => ({
      setMessage: state.setMessage,
   }));

   const run = React.useCallback(async () => {
      try {
         setLoading(true);
         setError(null);
         const res: ServiceResponse<T, any> = await fn();

         setData(res.data);

         if (res.error) {
            setError(res.error);
         }

         if (res.data?.message_overwrite) {
            setToastMessage(res.data?.message_overwrite || null);
         }
      } catch (e: any) {
         const message: string = e.error ? e.error : e.message;

         if (e.data) {
            setData(e.data);
         }

         setError(message);

         toast.setMessage(message, 'error');
      } finally {
         setLoading(false);
      }
   }, [fn]);

   React.useEffect(() => {
      return clear;
   }, []);

   function clear() {
      setError(null);
      setLoading(false);
      setData(null);
   }

   return {
      loading,
      error,
      run,
      data,
      clear,
      toastMessage,
   };
}

const storage = window.localStorage;

export class Storage {
   static getItem<T>(key: string): T | undefined | null {
      let v = storage.getItem(key);

      if (v) {
         try {
            v = JSON.parse(v);
         } catch (e) {}
      }

      // @ts-ignore
      return v;
   }

   static setItem(key: string, value: any): void {
      storage.setItem(key, JSON.stringify(value));
   }

   static removeItem(key: string): void {
      storage.removeItem(key);
   }
}

export default Storage;

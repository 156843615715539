export enum Constants {
   TOKEN_CHECK_INTERVAL = 3600000, // 1 hour
   ONE_DAY_IN_MILLIS = 86400000,
   DEFAULT_PAGE_SIZE = 25,
   CODE_MAX_LENGTH = 6,

   AGENT_ID_KEY = 'bridge.agent_id',
   AGENT_PASSWORD_RESET_REQUIRED = 'bridge.agent_password_reset_required',
   EXPERIMENTAL_SW_ENABLED = 'experimental.sw_enabled',
   BRIDGE_LAB = 'bridge.lab',

   DRAWER_DEFAULT_WIDTH = 300,

   IMAGES_BASE_URL = 'https://bridge-public-assets.s3.us-east-2.amazonaws.com/images/lighthouse',

   BRIDGE_SYSTEM_NAME = 'Bridge System',
}

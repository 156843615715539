import React from 'react';
import {
   Request,
   requestLoggingMiddleware,
   RequestMiddleware,
   responseLoggingMiddleware,
   ResponseMiddleware,
   signingMiddleware,
   Logger,
   EventEmitter,
   Constants,
   getKeypair,
   Keypair,
} from '@bridgemoney/core';

import { Events } from '../constants';
import { Storage } from '../services';
import { Constants as CoreConstants } from '@bridgemoney/core/lib/constants/Constants';
import { AxiosResponse } from 'axios';
import { Env } from '../utils/env';
import { Severity, ToastStore, useToastStore } from '../store';

const logger = new Logger('Initializer');

async function initServices(request: Request, mock: boolean = false): Promise<void> {
   let injectFn: ((request: Request) => void) | null = null;

   if (mock) {
      logger.debug(`init mocked API wrappers`);
      injectFn = (await import('../services/__mocks__/injectServices')).default;
   } else {
      injectFn = (await import('../services/injectServices')).default;
   }

   if (injectFn) {
      injectFn(request);
   }
}

function initRequestMiddlewares(
   request: Request,
   keyPair: Keypair,
   onHttpError: ((message: string) => void) | null = null,
   setMessage: (message: string | null, severity: Severity | null) => void,
   setHelperText: (message: string | null) => void,
): void {
   request.applyMiddleware(new RequestMiddleware(signingMiddleware.bind(null, keyPair)));
   request.applyMiddleware(new RequestMiddleware(requestLoggingMiddleware));
   request.applyMiddleware(new ResponseMiddleware(responseLoggingMiddleware));

   if (onHttpError) {
      request.applyMiddleware(
         new ResponseMiddleware((response: AxiosResponse) => {
            const { data, status, request } = response;

            if (status === 500) {
               throw new Error(JSON.stringify(data));
            }

            if (status === 409 && data.error && data.error === 'stale_object_error') {
               setMessage('Stale Object Error', 'error');
               setHelperText('Try to refresh the page or a specific object you are editing.');
            } else {
               if (status === 403) {
                  if (data.error) {
                     if (data.error !== 'password_reset_required') {
                        throw data;
                     }
                  } else {
                     onHttpError(`Request for ${request.responseURL} respond with ${JSON.stringify(data)}`);
                  }
               } else if (status < 100 || status > 300) {
                  if (data.error) {
                     throw data;
                  } else {
                     onHttpError(`Request for ${request.responseURL} respond with ${JSON.stringify(data)}`);
                  }
               }
            }
         }),
      );
   }
}

function getRandomBytes(length: number): Uint8Array {
   const arr = new Uint8Array(length);
   window.crypto.getRandomValues(arr);

   return arr;
}

function addToken(globalHeaders: any, token: string): void {
   globalHeaders[Constants.HEADER_AUTHORIZATION] = `Bearer ${token}`;
   delete globalHeaders[Constants.HEADER_SIG_KEY];

   logger.debug('Global Headers', globalHeaders);
}

function removeToken(globalHeaders: any, keyPair: Keypair): void {
   globalHeaders[Constants.HEADER_SIG_KEY] = keyPair.publicKey;
   delete globalHeaders[Constants.HEADER_AUTHORIZATION];

   logger.debug('Global Headers', globalHeaders);
}

export function useServiceInitialization(
   os: string,
   apiUrl: string,
   mock: boolean = false,
   onHttpError: ((message: string) => void) | null = null,
   env: Env,
): any[] {
   const [servicesInitialized, setServicesInitialized] = React.useState(false);
   const [keyPair, setKeypair] = React.useState<Keypair | null>(null);

   const setMessage = useToastStore((state: ToastStore) => state.setMessage);
   const setHelperText = useToastStore((state: ToastStore) => state.setHelperText);

   React.useEffect(() => {
      async function init() {
         logger.log('Init app with:', { os, apiUrl, mock, env });

         const keyPair: Keypair = await getKeypair(Storage, getRandomBytes);
         keyPair.nonce = getRandomBytes(24);
         setKeypair(keyPair);

         const globalHeaders: any = {
            [Constants.HEADER_USER_OS]: os,
            [Constants.HEADER_SIG_KEY]: keyPair.publicKey,
         };

         const request = new Request(apiUrl, globalHeaders);

         const token = Storage.getItem<string>(CoreConstants.TOKEN_KEY);
         const tokenExpiresAt = Storage.getItem<number>(CoreConstants.TOKEN_EXP_TIMESTAMP_KEY);

         if (token && tokenExpiresAt && tokenExpiresAt > new Date().getTime() / 1000) {
            addToken(globalHeaders, token);
         }

         await initServices(request, mock);
         initRequestMiddlewares(request, keyPair, onHttpError, setMessage, setHelperText);

         EventEmitter.on(Events.TOKEN_ACQUIRED, ({ token }: any) => {
            addToken(globalHeaders, token);
         });

         EventEmitter.on(Events.USER_LOGGED_OUT, () => {
            removeToken(globalHeaders, keyPair);
         });

         setServicesInitialized(true);
      }

      init();
   }, [os, apiUrl]);

   return [servicesInitialized, keyPair];
}

import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Paper.module.scss';

export interface PaperProps extends HTMLAttributes<HTMLDivElement> {
   elevation?: number;
   square?: boolean;
   hover?: boolean;
}

const Paper = React.memo(
   React.forwardRef<HTMLDivElement, PaperProps>(
      (
         { children, className, square = false, elevation = 0, hover = false, ...rest },
         ref: React.Ref<HTMLDivElement>,
      ) => {
         // @ts-ignore
         const elevationClassName: string = styles[`elevation${elevation}`];
         const squareClassName: string = square ? styles.square : '';
         const hoverClassName: string = hover ? styles.hover : '';

         return (
            <div
               ref={ref}
               className={clsx(styles.paper, squareClassName, elevationClassName, hoverClassName, className)}
               {...rest}
            >
               {children}
            </div>
         );
      },
   ),
);

export { Paper };
export default Paper;

import { create, GetState, SetState } from 'zustand';
import { persist } from 'zustand/middleware';
import { toggleToolsDrawer } from './actions/toolsDrawer';

export interface ToolsDrawerStore {
   instructionsId: any | null;
   showInstructions: (id: string | null | undefined) => void;
}

export interface PersistentToolsDrawerStore {
   opened: boolean;
   cardViews: { [key: string]: boolean };

   toggleToolsDrawer: (opened?: boolean) => void;
   resetCardViews: () => void;
}

export const useToolsDrawerStore = create<ToolsDrawerStore>()((set, get): ToolsDrawerStore => {
   return {
      instructionsId: null,
      showInstructions: (instructionsId: string | null | undefined) => {
         set({ instructionsId });
      },
   };
});

const persistentStore: any = persist(
   (
      set: SetState<PersistentToolsDrawerStore>,
      get: GetState<PersistentToolsDrawerStore>,
   ): PersistentToolsDrawerStore => {
      const defaultCardViews: any = {};

      return {
         opened: false,
         cardViews: defaultCardViews,

         toggleToolsDrawer: (opened?: boolean) => toggleToolsDrawer(set, get, opened),
         resetCardViews: () => {
            set({ cardViews: defaultCardViews });
         },
      };
   },
   {
      name: 'tools_drawer',
   },
);

export const usePersistentToolsDrawerStore = create<PersistentToolsDrawerStore>(persistentStore);

import { useTask } from '../useTask';
import { useInstance } from '@bridgemoney/core';
import { AgentsService } from '../../../services';
import { UserStore, useUserStore } from '../../UserStore';

export interface UseFetchAgent {
   fetchAgent: (id: string) => Promise<boolean>;
}

export function useFetchAgent(): UseFetchAgent {
   const task = useTask();

   const setAgent = useUserStore((state: UserStore) => state.setAgent);

   const service = useInstance<AgentsService>('AgentsService');

   async function fetchAgent(id: string): Promise<boolean> {
      return task.run(async () => {
         const res = await service.fetchAgent(id);

         if (res.data) {
            setAgent(res.data);
         }
      });
   }

   return {
      fetchAgent,
   };
}
